<template>
  <messages
    :ignore-scope="['news']"
    page-size="15"
    ignore-staff
    :message-id="messageId"
  />
</template>

<script>
import Messages from "@/components/msg/Messages";

export default {
  name: "Documents",
  props: {
    messageId: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    Messages,
  },
};
</script>
