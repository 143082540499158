<template>
  <v-card :loading="loading" v-show="showComponent">
    <v-toolbar color="titleBar" max-height="50" dark flat dense>
      <v-toolbar-title v-if="title">
        <v-badge
          :class="{
            'mr-4': true,
            'mt-2': newMessages > 0,
          }"
          :content="newMessages"
          :value="!loading && titleIcon != null && newMessages > 0"
          color="secondary"
          overlap
        >
          <v-icon v-if="titleIcon">{{ titleIcon }}</v-icon>
        </v-badge>
        {{ title }}
      </v-toolbar-title>

      <v-spacer />
      <v-tooltip
        top
        v-if="!dedicatedOnly && (!noDedicated || dedicatedMessages > 0)"
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-on="{ ...tooltip }"
            :disabled="loading"
            @click="dedicated = !dedicated"
            icon
          >
            <v-badge
              :content="dedicatedMessages"
              :value="dedicatedMessages > 0"
              color="secondary"
              overlap
            >
              <v-icon v-if="dedicated">mdi-star-check</v-icon>
              <v-icon v-else>mdi-star</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t("dedicated for me") }}</span>
      </v-tooltip>

      <v-menu bottom left v-if="!noFilter">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                :disabled="loadingScopes"
                :loading="loadingScopes"
              >
                <v-icon
                  v-if="
                    filteredScopes.filter((scope) => scope.filtered).length > 0
                  "
                >
                  mdi-filter-check
                </v-icon>
                <v-icon v-else> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("filter scope") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <template v-for="(scope, idx) in filteredScopes">
            <v-list-item
              :key="idx"
              @click.stop="
                scope.filtered = scope.filtered == null ? true : null;
                filteredScopes = [...filteredScopes];
              "
              dense
            >
              <v-list-item-icon>
                <v-icon v-if="scope.filtered == null || !scope.filtered" small>
                  mdi-filter
                </v-icon>
                <v-icon v-else small> mdi-filter-check </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ scopeNames[scope.slug] }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-tooltip top v-if="!noReload">
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getMessages" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text v-if="!noSearch">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            :label="$t('search')"
            single-line
            hide-details
            @click:append-outer="getMessages"
            @keyup.enter.native="getMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="!loading">
      <v-expansion-panels v-model="panel" hover flat>
        <v-expansion-panel
          v-for="msg in pagedMessages"
          :key="msg.id"
          class="elevation-1"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="2">
                <span
                  :class="{
                    'font-weight-bold': msg.is_new,
                  }"
                >
                  {{ formatDate(msg.publish_date) }}
                </span>
              </v-col>
              <v-col :cols="noScope ? 10 : 8">
                <v-icon v-if="msg.is_new" color="primary"> mdi-new-box </v-icon>
                <v-icon v-if="msg.client != null" color="primary">
                  mdi-star
                </v-icon>
                <v-badge
                  v-if="msg.documents.length > 0 && !noDownloadIcon"
                  :content="msg.documents.length"
                  :value="msg.documents.length > 1"
                >
                  <v-icon color="primary">mdi-download</v-icon>
                </v-badge>
                <span
                  :class="{
                    'font-weight-bold': msg.is_new,
                    'news-title': true,
                  }"
                >
                  {{ msg.title }}
                </span>
              </v-col>
              <v-col cols="2" v-if="!noScope">
                <v-chip-group>
                  <template v-for="(s, idx) in msg.scope">
                    <v-chip
                      v-if="!hiddenScopes.includes(s.toLowerCase())"
                      :key="idx"
                      class="mr-1 mb-1"
                      :style="{ color: scopeColors[s] }"
                      outlined
                    >
                      {{ scopeNames[s] }}
                    </v-chip>
                  </template>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span
              :class="{
                'news-message': true,
              }"
              v-html="msg.text"
            />
            <v-divider v-if="msg.documents.length > 0" class="mb-2" />
            <v-row
              v-for="doc in msg.documents"
              :key="doc.id"
              no-gutters
              align="center"
              align-content="center"
            >
              <v-col cols="1">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      color="primary"
                      @click="downloadDocument(msg, doc)"
                    >
                      <v-icon small>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("download") }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="11" class="text-caption">
                {{ doc.title || doc.filename }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-text v-if="pages > 1">
      <div class="text-center">
        <v-pagination
          v-if="noServerPagination"
          v-model="page"
          :length="pages"
          :total-visible="3"
          circle
        />
        <v-pagination
          v-else
          v-model="page"
          :length="pages"
          :total-visible="3"
          circle
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import downloadFile from "@/utils/mixins/downloadFile";
import messageTools from "@/utils/mixins/messageTools";

export default {
  name: "Messages",
  mixins: [formatDate, formatDateTime, isMobile, downloadFile, messageTools],
  props: {
    messageId: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    titleIcon: {
      type: String,
      default: null,
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    noReload: {
      type: Boolean,
      default: false,
    },
    noScope: {
      type: Boolean,
      default: false,
    },
    noServerPagination: {
      type: Boolean,
      default: false,
    },
    noDownloadIcon: {
      type: Boolean,
      default: false,
    },
    ignoreScope: {
      type: Array,
      default() {
        return [];
      },
    },
    ignoreStaff: {
      type: Boolean,
      default: false,
    },
    noDedicated: {
      type: Boolean,
      default: false,
    },
    dedicatedOnly: {
      type: Boolean,
      default: false,
    },
    onlyScope: {
      type: Array,
      default() {
        return [];
      },
    },
    pageSize: {
      type: [String, Number],
      default: 15,
    },
    daysAgo: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    search: null,
    dedicated: false,
    messages: [],
    newMessages: 0,
    dedicatedMessages: 0,
    filteredScopes: [],
    page: 1,
    pages: 0,
    loading: false,
    delayTimer: null,
    panel: null,
  }),
  computed: {
    showComponent() {
      return (
        this.messages.length > 0 &&
        (!this.dedicatedOnly || this.dedicatedMessages > 0)
      );
    },
    pagedMessages() {
      if (this.noServerPagination) {
        var i = 0;
        var start = (this.page - 1) * Number(this.pageSize) + 1;
        var end = start + Number(this.pageSize) - 1;
        return this.messages.filter(function (item) {
          i += 1;
          return i >= start && i <= end;
        });
      } else return this.messages;
    },
    hiddenScopes() {
      var that = this;
      this.ignoreScope;
      return this.scopes
        .filter(function (scope) {
          return (
            !scope.is_public ||
            that.ignoreScope.map((s) => s.toLowerCase()).includes(scope.slug)
          );
        })
        .map((scope) => scope.slug);
    },
  },
  watch: {
    "$store.state.session"() {
      this.getMessages();
    },
    scopes: {
      immediate: true,
      handler() {
        var that = this;
        if (this.scopes)
          this.filteredScopes = this.scopes.filter(function (scope) {
            return !that.hiddenScopes.includes(scope.slug);
          });
      },
    },
    search(value) {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.page = 1;
        that.getMessages();
      }, 850);
    },
    filteredScopes() {
      this.getMessages();
    },
    page() {
      if (!this.noServerPagination) this.getMessages();
    },
    pageSize() {
      if (!this.noServerPagination) this.getMessages();
    },
    ignoreScope() {
      this.getMessages();
    },
    noSearch() {
      this.search = null;
    },
    dedicated() {
      this.getMessages();
    },
  },
  methods: {
    getMessages() {
      var that = this;
      this.loading = true;
      this.$http
        .get("messages", {
          params: {
            q: this.search,
            client:
              this.dedicated || this.dedicatedOnly
                ? this.$store.getters.customerNumber
                : null,
            is_incoming: this.dedicated || this.onlyScope ? false : null,
            days_ago: this.daysAgo,
            scope:
              this.onlyScope.length > 0
                ? this.onlyScope
                : this.filteredScopes
                    .filter((scope) => scope.filtered)
                    .map((scope) => scope.slug),
            ignore_scope: this.ignoreScope,
            ignore_staff: this.ignoreStaff,
            ordering: "-publish_date",
            page_number: this.noServerPagination ? null : this.page,
            page_size: this.noServerPagination ? null : this.pageSize,
          },
        })
        .then((response) => {
          if (that.noServerPagination) {
            that.messages = response.data;
            that.pages = Math.ceil(
              that.messages.length / Number(that.pageSize)
            );
            that.newMessages = that.messages.filter((msg) => msg.is_new).length;
            that.dedicatedMessages = that.messages.filter(
              (msg) => msg.client != null
            ).length;
          } else {
            that.messages = response.data.results;
            that.pages = response.data.pages;
            that.newMessages = 0;
            that.dedicatedMessages = 0;
          }

          if (that.messageId != null) {
            var idx = 0;
            that.messages.forEach(function (msg) {
              if (msg.id == that.messageId) that.panel = idx;
              idx = idx + 1;
            });
          }

          that.$emit("foundDedicatedMessages", that.dedicatedMessages);
          that.$emit("foundNewMessages", that.newMessages);
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    downloadDocument(msg, doc) {
      this.downloadFile(
        "messages/" + msg.id + "/documents/" + doc.id + "/download",
        "get",
        {},
        doc.filename
      );
    },
  },
  mounted() {
    this.getMessages();
  },
};
</script>

<i18n>
  {
    "en": {
      "reload": "reload messages",
      "filter scope": "filter scope",
      "search": "Search",
      "download": "download",
      "dedicated for me": "dedicated for me"
    },
    "de": {
      "reload": "Nachrichten neu einlesen",
      "filter scope": "Bereiche filtern",
      "search": "Suche",
      "download": "herunterladen",
      "dedicated for me": "nur für mich"
    }
  }
</i18n>

<style scoped>
span.news-title {
  padding: 0px 0px 0px 20px;
}

span.news-message {
  padding: 0px 0px 0px 12px;
}
</style>
