var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showComponent),expression:"showComponent"}],attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"titleBar","max-height":"50","dark":"","flat":"","dense":""}},[(_vm.title)?_c('v-toolbar-title',[_c('v-badge',{class:{
          'mr-4': true,
          'mt-2': _vm.newMessages > 0,
        },attrs:{"content":_vm.newMessages,"value":!_vm.loading && _vm.titleIcon != null && _vm.newMessages > 0,"color":"secondary","overlap":""}},[(_vm.titleIcon)?_c('v-icon',[_vm._v(_vm._s(_vm.titleIcon))]):_vm._e()],1),_vm._v(" "+_vm._s(_vm.title)+" ")],1):_vm._e(),_c('v-spacer'),(!_vm.dedicatedOnly && (!_vm.noDedicated || _vm.dedicatedMessages > 0))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){_vm.dedicated = !_vm.dedicated}}},{ ...tooltip }),[_c('v-badge',{attrs:{"content":_vm.dedicatedMessages,"value":_vm.dedicatedMessages > 0,"color":"secondary","overlap":""}},[(_vm.dedicated)?_c('v-icon',[_vm._v("mdi-star-check")]):_c('v-icon',[_vm._v("mdi-star")])],1)],1)]}}],null,false,676495456)},[_c('span',[_vm._v(_vm._s(_vm.$t("dedicated for me")))])]):_vm._e(),(!_vm.noFilter)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.loadingScopes,"loading":_vm.loadingScopes}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[(
                  _vm.filteredScopes.filter((scope) => scope.filtered).length > 0
                )?_c('v-icon',[_vm._v(" mdi-filter-check ")]):_c('v-icon',[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("filter scope")))])])]}}],null,false,2767922749)},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.filteredScopes),function(scope,idx){return [_c('v-list-item',{key:idx,attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();scope.filtered = scope.filtered == null ? true : null;
              _vm.filteredScopes = [..._vm.filteredScopes];}}},[_c('v-list-item-icon',[(scope.filtered == null || !scope.filtered)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter-check ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.scopeNames[scope.slug])+" ")])],1)],1)]})],2)],1):_vm._e(),(!_vm.noReload)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":_vm.getMessages}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,false,238757533)},[_c('span',[_vm._v(_vm._s(_vm.$t("reload")))])]):_vm._e()],1),(!_vm.noSearch)?_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"click:append-outer":_vm.getMessages},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getMessages.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1):_vm._e(),(!_vm.loading)?_c('v-card-text',[_c('v-expansion-panels',{attrs:{"hover":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.pagedMessages),function(msg){return _c('v-expansion-panel',{key:msg.id,staticClass:"elevation-1"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{class:{
                  'font-weight-bold': msg.is_new,
                }},[_vm._v(" "+_vm._s(_vm.formatDate(msg.publish_date))+" ")])]),_c('v-col',{attrs:{"cols":_vm.noScope ? 10 : 8}},[(msg.is_new)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-new-box ")]):_vm._e(),(msg.client != null)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-star ")]):_vm._e(),(msg.documents.length > 0 && !_vm.noDownloadIcon)?_c('v-badge',{attrs:{"content":msg.documents.length,"value":msg.documents.length > 1}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1):_vm._e(),_c('span',{class:{
                  'font-weight-bold': msg.is_new,
                  'news-title': true,
                }},[_vm._v(" "+_vm._s(msg.title)+" ")])],1),(!_vm.noScope)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip-group',[_vm._l((msg.scope),function(s,idx){return [(!_vm.hiddenScopes.includes(s.toLowerCase()))?_c('v-chip',{key:idx,staticClass:"mr-1 mb-1",style:({ color: _vm.scopeColors[s] }),attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.scopeNames[s])+" ")]):_vm._e()]})],2)],1):_vm._e()],1)],1),_c('v-expansion-panel-content',[_c('span',{class:{
              'news-message': true,
            },domProps:{"innerHTML":_vm._s(msg.text)}}),(msg.documents.length > 0)?_c('v-divider',{staticClass:"mb-2"}):_vm._e(),_vm._l((msg.documents),function(doc){return _c('v-row',{key:doc.id,attrs:{"no-gutters":"","align":"center","align-content":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadDocument(msg, doc)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("download")))])])],1),_c('v-col',{staticClass:"text-caption",attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(doc.title || doc.filename)+" ")])],1)})],2)],1)}),1)],1):_vm._e(),(_vm.pages > 1)?_c('v-card-text',[_c('div',{staticClass:"text-center"},[(_vm.noServerPagination)?_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":3,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":3,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }